import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export const LandingPage = () => {
  return (
    <>
      <Row>
        <Col>
          <h1 className="header-title">DahSampai</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="description">
            A mobile application that makes pick-up easier and more convenient.
          </p>
          <p className="description">
            Notify your child's teacher with just one click when you arrive at
            the school.
          </p>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <p className="footer-text">&copy; 2023 DahSampai</p>
        </Col>
      </Row>
    </>
  );
};
