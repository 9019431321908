import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { useNavigate } from "react-router";
import { useUserAuth } from "../../context/UserAuthContext";

export const Header = () => {
  const [isAuth, setAuth] = useState(false);
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.auth) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [user]);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/login");
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <Navbar
      bg="primary"
      variant="dark"
      expand="lg"
      className="margin-bottom-2rem"
    >
      <Container>
        <Navbar.Brand href="/">DahSampai</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            {isAuth ? (
              <>
                <Nav.Link href="/profile">Profile</Nav.Link>
                <Nav.Link href="/queue">Queue</Nav.Link>
                <Nav.Link href="/students">Student</Nav.Link>
                <Nav.Link onClick={handleLogout}>Log out</Nav.Link>
              </>
            ) : (
              // <Nav.Link href="/login">Login</Nav.Link>
              <Nav.Link href="/privacypolicy">Privacy Policy</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
