import { Route, Routes } from "react-router-dom";
import "./App.css";

import Container from "react-bootstrap/Container";

import { UserAuthContextProvider } from "./context/UserAuthContext";

import { ForgotPassword, Login } from "./views/Auth";
import { Header } from "./views/Shared";
import { Profile, NewProfile } from "./views/Profile";
import { Queue } from "./views/Queue";
import { EditStudent, NewStudent, Student } from "./views/Student";
import { LandingPage, PrivacyPolicy } from "./views/Website";

function App() {
  return (
    <>
      <UserAuthContextProvider>
        <Header />
        <Container>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            {/* <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/new" element={<NewProfile />} />
            <Route path="/queue" element={<Queue />} />
            <Route path="/students" element={<Student />} />
            <Route path="/student/new" element={<NewStudent />} />
            <Route path="/student/edit" element={<EditStudent />} /> */}
          </Routes>
        </Container>
      </UserAuthContextProvider>
    </>
  );
}

export default App;
