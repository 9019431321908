import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export const PrivacyPolicy = () => {
  return (
    <Row>
      <Col></Col>
      <Col xs={7}>
        <Card>
          <Card.Body>
            <h1>Privacy Policy</h1>
            <p>
              We take your privacy seriously and are committed to protecting the
              personal information you provide through our mobile application.
              This Privacy Policy explains what information we collect and how
              we use it.
            </p>
            {/* <br /> */}
            <hr />
            <h3>Information Collection</h3>
            <p>Our app collects the following information:</p>
            <ul>
              <li>
                Location data: To determine if you have arrived within the
                radius of the school, we collect your device’s location data.
              </li>
            </ul>
            <h3>Information Use</h3>
            <p>
              The information we collect is used for the following purposes:
            </p>
            <ul>
              <li>
                To notify the school teacher that you have arrived within the
                radius of the school.
              </li>
              <li>To improve the functionality of our app.</li>
            </ul>
            <h3>Information Sharing</h3>
            <p>
              We do not sell or rent your personal information to third parties.
              We may share your information with third-party service providers
              as necessary to provide you with the app’s services. We require
              these third parties to maintain the confidentiality and security
              of your information.
            </p>
            <h3>Data Security</h3>
            <p>
              We implement appropriate technical and organizational measures to
              secure your personal information from unauthorized access,
              alteration, or destruction.
            </p>
            <h3>Changes to this Privacy Policy</h3>
            <p>
              We may update this Privacy Policy from time to time. When we do,
              we will revise the “last updated” date at the top of this policy.
              If we make any material changes to this policy, we will provide
              you with additional notice.
            </p>
            <h3>Contact Information</h3>
            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at admin@hanristech.com.
            </p>
          </Card.Body>
        </Card>
      </Col>
      <Col></Col>
    </Row>
  );
};
